import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'

export const primaries = [
  { label: 'documentType', value: '{{documentType}}' },
  { label: 'document', value: '{{document}}' },
  { label: 'photo', value: '{{photo}}' },
  { label: CDPFieldsEnums.PRIORITY, value: '{{priority}}' },
  { label: 'status', value: '{{status}}' },
  { label: 'alias', value: '{{alias}}' },
  { label: CDPFieldsEnums.NAME, value: '{{name}}' },
  { label: CDPFieldsEnums.MIDNAME, value: '{{midname}}' },
  { label: CDPFieldsEnums.SURNAME, value: '{{surname}}' },
  { label: 'description', value: '{{description}}' },
  { label: 'disambiguatingDescription', value: '{{disambiguatingDescription}}' },
  { label: CDPFieldsEnums.PHONE, value: '{{phone}}' },
  { label: 'email', value: '{{email}}' },
  { label: CDPFieldsEnums.URL_PUBLIC, value: '{{urlPublic}}' },
  { label: CDPFieldsEnums.PUBLIC_SOCIAL_MEDIA, value: '{{publicSocialmedia}}' },
  { label: 'knows', value: '{{knows}}' },
  { label: 'birthDate', value: '{{birthDate}}' },
  { label: 'birthPlaceCountry', value: '{{birthPlaceCountry}}' },
  { label: 'birthPlaceProvince', value: '{{birthPlaceProvince}}' },
  { label: 'birthPlaceCity', value: '{{birthPlaceCity}}' },
  { label: 'deathDate', value: '{{deathDate}}' },
  { label: 'deathPlaceCountry', value: '{{deathPlaceCountry}}' },
  { label: 'deathPlaceProvince', value: '{{deathPlaceProvince}}' },
  { label: 'deathPlaceCity', value: '{{deathPlaceCity}}' },
  { label: 'nationality', value: '{{nationality}}' },
  { label: 'colleage', value: '{{colleage}}' },
  { label: 'affiliation', value: '{{affiliation}}' },
  { label: 'award', value: '{{award}}' },
  { label: 'duns', value: '{{duns}}' },
  { label: 'establishmentCountry', value: '{{establishmentCountry}}' },
  { label: 'establishmentProvince', value: '{{establishmentProvince}}' },
  { label: 'establishmentCity', value: '{{establishmentCity}}' },
  { label: 'establishmentDate', value: '{{establishmentDate}}' },
  { label: 'childrenNumber', value: '{{childrenNumber}}' },
  { label: 'zone', value: '{{zone}}' },
  { label: 'profession', value: '{{profession}}' },
  { label: 'source', value: '{{source}}' },
  { label: 'preferredProduct', value: '{{preferredProduct}}' },
  { label: 'registerChannel', value: '{{registerChannel}}' },
  { label: 'hearAbout', value: '{{hearAbout}}' },
  { label: 'taxCode', value: '{{taxCode}}' },
  { label: 'taxGroup', value: '{{taxGroup}}' },
  { label: 'nps', value: '{{nps}}' },
  { label: 'timeZone', value: '{{timeZone}}' },
  { label: CDPFieldsEnums.CATEGORY, value: '{{category}}' },
  { label: 'subCategory', value: '{{subCategory}}' },
  { label: 'tags', value: '{{tags}}' },
  { label: 'formId', value: '{{formId}}' },
  { label: 'leadGenId', value: '{{leadGenId}}' },
  { label: 'preferredStore', value: '{{preferredStore}}' },
  { label: 'business', value: '{{business}}' },
  { label: 'office', value: '{{office}}' },
  { label: 'country', value: '{{country}}' },
  { label: 'province', value: '{{province}}' },
  { label: 'city', value: '{{city}}' },
  { label: CDPFieldsEnums.ADDRESS, value: '{{address}}' }
]

export const demographics = [
  { label: 'occupations', value: '{{occupations}}' },
  { label: 'preferredLanguage', value: '{{preferredLanguage}}' },
  { label: 'monthlyIncome', value: '{{monthlyIncome}}' },
  { label: 'annualIncome', value: '{{annualIncome}}' },
  { label: CDPFieldsEnums.LANGUAGE, value: '{{language}}' },
  { label: 'ethnicity', value: '{{ethnicity}}' },
  { label: 'civilStatus', value: '{{civilStatus}}' },
  { label: 'religion', value: '{{religion}}' },
  { label: 'socioeconomicStatus', value: '{{socioeconomicStatus}}' },
  { label: 'gender', value: '{{gender}}' },
  { label: 'educationalLevel', value: '{{educationalLevel}}' },
  { label: 'postalCode', value: '{{address.postalCode}}' },
  { label: 'typeAddress', value: '{{address.typeAddress}}' },
  { label: 'province', value: '{{address.province}}' },
  { label: 'city', value: '{{address.city}}' },
  { label: 'adress', value: '{{address}}' },
  { label: 'country', value: '{{address.country}}' },
  { label: 'zone', value: '{{address.zone}}' },
  { label: 'headquarters', value: '{{headquarters}}' },
  { label: 'Credential', value: '{{Credential}}' }
]

export const transactional = [
  { label: CDPFieldsEnums.CREDIT_SCORE, value: '{{creditScore}}' },
  { label: CDPFieldsEnums.PLAN, value: '{{plan}}' },
  { label: CDPFieldsEnums.CATEGORY, value: '{{category}}' },
  { label: CDPFieldsEnums.PRIORITY, value: '{{priority}}' },
  { label: CDPFieldsEnums.PRODUCTS, value: '{{products}}' },
  { label: CDPFieldsEnums.ABANDONED_CART, value: '{{abandonedCart}}' },
  { label: CDPFieldsEnums.ORDERS, value: '{{orders}}' },
  { label: CDPFieldsEnums.PAYMENTS, value: '{{payments}}' },
  { label: CDPFieldsEnums.BANK_ACCOUNT, value: '{{bankAccount}}' },
  { label: CDPFieldsEnums.PERSONAL_REFERENCE, value: '{{personalReference}}' },
  { label: CDPFieldsEnums.PROOF_OF_ADDRESS, value: '{{proofOfAddress}}' },
  { label: CDPFieldsEnums.PROOF_OF_BANK_ACCOUNT, value: '{{proofOfBankAccount}}' },
  { label: CDPFieldsEnums.PROOF_OF_BUSINESS, value: '{{proofOfBusiness}}' },
  { label: CDPFieldsEnums.PROOF_OF_LOCATION, value: '{{proofOfLocation}}' },
  { label: 'bankAccount.type', value: '{{bankAccount.type}}' },
  { label: 'bankAccount.name', value: '{{bankAccount.name}}' },
  { label: 'personalReference.parentage', value: '{{personalReference.parentage}}' },
  { label: 'personalReference.telephone', value: '{{personalReference.telephone}}' }
]

export const brand = [
  { label: 'businessType', value: '{{businessType}}' }
]

export const personality = [
  { label: CDPFieldsEnums.INTERESTS, value: '{{personalityData.interests}}' },
  { label: CDPFieldsEnums.LIKES, value: '{{personalityData.likes}}' },
  { label: CDPFieldsEnums.DISLIKES, value: '{{personalityData.dislikes}}' },
  { label: CDPFieldsEnums.MUSIC, value: '{{personalityData.music}}' },
  { label: CDPFieldsEnums.GENERAL, value: '{{personalityData.general}}' },
  { label: CDPFieldsEnums.NATURAL_TALENTS, value: '{{personalityData.naturalTalents}}' },
  { label: CDPFieldsEnums.PERSONALITY, value: '{{personalityData.personality}}' },
  { label: CDPFieldsEnums.SKILLS, value: '{{personalityData.skills}}' },
  { label: CDPFieldsEnums.COLOR_GAMUT, value: '{{personalityData.colorGamut}}' },
  { label: CDPFieldsEnums.COLOR_FAVORITE, value: '{{personalityData.colorFavorite}}' },
  { label: CDPFieldsEnums.PREFERRED_CHANNEL, value: '{{personalityData.preferredChannel}}' },
  { label: CDPFieldsEnums.TREATMENT_PREFERRED, value: '{{personalityData.treatmentPreferred}}' },
  { label: CDPFieldsEnums.TREATMENT_TYPE, value: '{{personalityData.treatmentType}}' },
  { label: CDPFieldsEnums.PREFERRED_CONTACT_TIME, value: '{{personalityData.preferredContactTime}}' },
  { label: CDPFieldsEnums.PREFERRED_GREETING, value: '{{personalityData.preferredGreeting}}' },
  { label: CDPFieldsEnums.PREFERRED_GOODBYE, value: '{{personalityData.preferredGoodbye}}' },
  { label: CDPFieldsEnums.PREFERRED_PRONOUNS, value: '{{personalityData.preferredPronouns}}' },
  { label: CDPFieldsEnums.PREFERRED_CULTURE, value: '{{personalityData.preferredCulture}}' }
]

export const habeasData = [
  { label: CDPFieldsEnums.HABEAS_DATA_STATUS, value: '{{habeasData.habeasDataStatus}}' },
  { label: CDPFieldsEnums.HABEAS_DATA_ACCEPTED, value: '{{habeasData.habeasDataAccepted}}' },
  { label: CDPFieldsEnums.HABEAS_DATA_DATE, value: '{{habeasData.habeasDataDate}}' },
  { label: CDPFieldsEnums.HABEAS_DATA_COUNTRY, value: '{{habeasData.habeasDataCountry}}' },
  { label: CDPFieldsEnums.HABEAS_DATA_REMOVED, value: '{{habeasData.habeasDataRemoved}}' },
  { label: CDPFieldsEnums.HABEAS_DATA_DATE, value: '{{habeasData.habeasDataDate}}' },
  { label: CDPFieldsEnums.HABEAS_DATA_REMOVED_COUNTRY, value: '{{habeasData.habeasDataRemovedCountry}}' },
  { label: CDPFieldsEnums.HABEAS_DATA_REMOVED_DATE, value: '{{habeasData.habeasDataRemovedDate}}' }
]
