import { mapState } from 'vuex'
import KeybeAvatar from '@/shared/infrastructure/ui/KeybeAvatar.vue'

export default {
  name: 'WebPreview',
  components: {
    KeybeAvatar
  },
  props: {
    greetingMessage: {
      type: String,
      default: ''
    },
    messageImage: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      grettingFormat: ''
    }
  },
  computed: {
    ...mapState('UserStore', ['user']),
    userName () {
      if (!this.user?.name) {
        return ''
      }
      if (!this.user?.lastName) {
        return this.user?.name
      }
      return `${this.user.name} ${this.user.lastName}`
    }
  },
  watch: {
    greetingMessage: {
      handler: 'messageText',
      immediate: true
    }
  },
  methods: {
    messageText ():string {
      const baseText = this.greetingMessage
      this.grettingFormat = baseText.replace('{{username}}', `'${this.$t('userName')}'`).replace('{adviserName}', this.userName)
      return this.grettingFormat
    }
  }
}
