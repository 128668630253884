export interface TaskContact {
  uuid: string;
  name: string;
  phone: string;
}

export interface TaskResponsible {
  uuid: string;
  name: string;
  email: string;
  phone: string;
}

export interface TaskCreator {
  userId: string;
  type: string;
  name: string;
  lastname: string;
  email: string;
}

export type TaskPriority = 'highest' | 'high' | 'medium' | 'low' | 'lowest';

export enum TaskPriorityEnum {
  HIGHEST = 'highest',
  HIGH = 'high',
  MEDIUM = 'medium',
  LOW = 'low',
  LOWEST = 'lowest'
}

type TaskStatus = 'created' | 'process' | 'done' | 'expired';

export enum TaskStatusEnum {
  CREATED = 'created',
  PROCESS = 'process',
  DONE = 'done',
  EXPIRED = 'expired'
}

export interface TaskType {
  _id: string;
  name: string;
  nameSlug: string;
}

export interface Task {
  _id: string;
  app: string;
  contact: TaskContact;
  responsible: TaskResponsible;
  title: string;
  description: string;
  date: string;
  priority: TaskPriority;
  status: TaskStatus;
  inactive: boolean;
  updatedBy: TaskCreator;
  taskType: TaskType;
  createdBy: TaskCreator;
  extraData: any;
}
